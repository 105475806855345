import metas from 'locales/metas.json';
import {
	getLegacyLocaleFromLocale,
	getLocaleFromLegacyLocale,
	getISOLocaleFromLocale,
} from '@deezer/deezer-compatibility';
/**
 * Returns the country code for a given locale.
 *
 * @param locale - The locale.
 * @param locales - The available locale list.
 */
export const getCountryFromLocale = (
	locale: string,
	locales = metas.availableLanguages,
): string => {
	if (!locales.includes(locale)) {
		throw new Error(
			`i18n: the locale "${locale}" is invalid or not supported.`,
		);
	}
	return getLegacyLocaleFromLocale(locale);
};

/**
 * Returns the locale for a given country code.
 *
 * @param country - The country code.
 * @param locales - The available locale list.
 */
export const getLocaleFromCountry = (
	country: string,
	locales: string[],
): string => {
	const locale = getLocaleFromLegacyLocale(country);
	if (!locales.includes(locale)) {
		throw new Error(
			`i18n: the country code "${country}" is invalid or not supported.`,
		);
	}
	return locale;
};

/**
 * Returns the GDPR language for a given locale.
 *
 * @param locale - The locale.
 * @param locales - The available locale list.
 */
export const getGdprLangFromLocale = (
	locale: string,
	locales: string[],
): string => {
	if (!locales.includes(locale)) {
		throw new Error(
			`i18n: the locale "${locale}" is invalid or not supported.`,
		);
	}
	return getISOLocaleFromLocale(locale);
};
